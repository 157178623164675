export function ZvrkiBodySVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="139"
      height="171"
      fill="none"
      viewBox="0 0 139 171"
    >
      <path
        fill="#817C77"
        d="m34.904 62.477 17.144-18.624 68.699-2.097-14.554 63.149-1.85 40.208-4.933 2.836-11.347 2.344-27.505 6.167-6.906 3.576h-10.36l-6.784-3.576v3.576h-13.69L9.25 156.46l1.357-11.347L0 127.229l1.233-18.871 18.378-11.224v-9.003l15.293-25.654Z"
      />
      <path
        fill="#E3E3E3"
        d="m27.504 106.755 15.787-17.637L111.25 84.8l-7.893 60.065-49.459 14.06H7.524l3.206-14.06h5.427l11.347 5.427 10.237-9.744-3.083-6.29v-6.29H31.82l2.837-6.29-7.154-3.947v-10.977Z"
      />
      <path
        fill="#fff"
        d="m42.058 39.536 5.18-9.62S81.032 15.609 103.233.808h5.057l.987 3.824 7.153 4.44-1.48 2.96 3.7 6.167-2.22 3.206 4.194 2.344-4.194 3.083h6.29v5.304l3.084-2.22 1.233 3.33h6.66l-1.85 4.44 5.304 3.453-6.414 2.467 7.894 3.33-7.894 2.344-9.003-1.11 9.003 6.29h-4.933l4.933 5.427h-1.973l1.973 4.81-8.017-2.59V77.4l-7.77 19.858-5.673 18.747-2.591 15.294-3.453 12.827 3.453 7.277 5.674 8.633-5.674 5.55-6.29-1.603-7.647-6.043-1.726-13.814-3.207-17.021 4.933-17.02 5.674-21.46-7.4 14.306v-8.387l-3.207 9.744v-9.744l-.617 6.661-3.7-11.1v8.633L78.442 85.54l.124 4.563-2.714-3.7 1.48 9.004 3.207 7.03-1.233-1.357v5.427l-3.577-8.634 3.577 12.457-1.48-1.11 2.713 9.374-1.973-1.973 1.973 8.633-1.973-1.603.74 11.224-1.48 5.426v9.251l4.07 14.183-4.07 4.317-2.96.617-3.7-.617-5.18 2.097-4.44-2.097-7.401-44.894-2.22-10.607v-10.114l-2.714-3.084 2.714-2.466v-8.634l-7.894-1.48L54.145 75.55l-4.934 4.44 2.96-7.646-8.14 3.206 5.92-8.757h-5.92l8.14-10.607h-8.14l5.92-8.016h-7.893l5.18-5.674-5.18-2.96Z"
      />
      <path
        fill="#817C77"
        d="m82.512 80.537-2.343-8.634V65.12l2.343 1.11 3.947-3.577h6.66l4.687 6.043-6.907 11.84-1.356-5.55-3.084 8.634v-7.523l-2.22 4.44-1.727-4.44v4.44Z"
      />
    </svg>
  );
}
