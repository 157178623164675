export function BusinessCatSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="128"
      height="171"
      fill="none"
      viewBox="0 0 128 171"
    >
      <rect width="128" height="128" y="25" fill="#3141CE" rx="64" />
      <path
        fill="#000"
        fillRule="evenodd"
        d="M114.152 99.667v7.134l8.561 1.098v-8.232h-8.561Zm1.427 5.707v-4.28l5.707 1.426v4.281l-5.707-1.427Z"
        clipRule="evenodd"
      />
      <path
        fill="#505050"
        d="M126.994 161.021v-52.793l-4.281-.329-8.561-1.098h-5.708v54.22h18.55Z"
      />
      <path
        fill="#FFBA53"
        d="m4.149 73.59 7.292 19.235 17.062 25.913 11.74-33.574-1.99-12.812 1.99-6.2-6.371 1.24 2.365-15.687-9.658-3.548-14.674-5.912L1.21 49.54l2.94 24.05Z"
      />
      <path
        fill="#fff"
        d="m23.202 52.548 4.546 8.944-3.126.811-4.01-4.02-3.64 3.114-6.273-5.603 1.935 7.452-5.913-1.358 2.08-1.985-.036-11.56 7.843 7.363 1.543-6.908 5.24 8.763-.19-5.013Z"
      />
      <path
        fill="#FFCF71"
        d="M72.938 7.173c2.48-3.627 3.874-4.358 13.165-5.535h4.58l11.066 6.298 4.77-2.672 2.462 14.869 2.854 1.427-1.427 7.134 2.854 1.427-2.545 7.134v7.134l3.625 9.988v29.138l-1.08 6.534 1.08 6.253v17.94l-11.068 24.32-1.525 12.841v15.696l-19.878 1.426-9.697 1.638h-19.27v-24.621H45.08l-6.487 13.933H30.77l-9.158-24.239 10.32-36.626 8.56-27.11 9.988-24.257 4.28-14.268-4.28-4.28 7.135-1.428-2.854-2.854 4.82-5.216L63.397.11l9.54 7.062Z"
      />
      <path
        fill="#1B1A1A"
        d="m73.317 10.8 3.626-2.29 3.053 1.717-1.718.954-4.96-.381Zm27.01 1.057-3.02-3.045-3.36.99 1.46 1.314 4.92.741Z"
      />
      <path
        fill="#FC9951"
        d="m85.719 6.41-3.244 3.435 5.343 1.336 4.197-.955-.381-1.717-2.29-2.1H85.72Z"
      />
      <path
        fill="#fff"
        d="m62.067 17.178 1.896-14.223 2.844 4.741-2.844 11.379-13.271 26.55-4.74 28.446-9.741 23.112-11.114 23.35 8.26-26.204 6.907-22.155 13.07-33.492 8.733-21.504Zm43.517 1.616 2.933 4.074-2.508-8.293-1.471-6.541-1.311 4.556.673 1.772 1.684 4.432Z"
      />
      <path
        fill="#55678A"
        d="M104.7 44.39H67.602L54.761 32.974 47.626 48.67l-17.122 51.367 38.525 24.256 45.659-19.976 1.427-21.403 1.427-17.122-2.854-14.268-2.853-17.123-7.135 9.988Z"
      />
      <path
        fill="#FFBA53"
        d="m55.687 94.425-1.029-5.613 8.164-2.947 7.274.117 8.047 4.328-3.954 2.175 5.24 4.843-2.55 1.917 1.286 7.018-3.321-2.293.28 17.356 4.769 33.941-6.76 2.69-8.046-4.328-8.863-40.444-3.204-9.567 4.21-.772-7.157-7.392 5.614-1.028Z"
      />
      <path
        fill="#fff"
        d="m54.75 108.948-.925-4.29.606-1.253.928.922 1.622 4.14-.187 1.163 1.343 4.2-.047 1.132 1.155 5.363-.74-2.085 2.494 12.931 5.41 25.771-.467 1.223-1.622-4.14-6.844-31.074-1.758-7.477.419-.091-1.945-6.315.558-.12Z"
      />
      <path
        fill="#FFBA53"
        d="m105.472 93.887.886-5.638-8.235-2.74-7.27.302-7.934 4.53 4.007 2.074-5.115 4.973 2.597 1.853-1.108 7.048 3.262-2.376.16 17.357-5.019 41.098 6.826 2.518 7.935-4.53 8.945-47.702 2.961-9.645-4.228-.665 6.968-7.57-5.638-.887Z"
      />
      <path
        fill="#fff"
        d="m87.1 106.82.353-4.375.942-1.024.623 1.151.358 4.432-.516 1.059.074 4.409-.373 1.071-.442 5.468-.108-2.211-1.344 13.101-2.263 26.235-.8 1.036-.357-4.432 2.42-31.726.476-7.667.427.035-.039-6.608.569.046Zm6.25-85.849H81.026l.948-.948 1.896-1.896 7.584-.948v2.844h.948l.948.948Z"
      />
      <path
        fill="#000"
        d="m79.017 54.331-14.269-8.56 1.427-2.854 14.269 1.427h19.976l4.28 5.707-18.549 4.28 6.868 46.084-14.002 13.844-11.68-13.844 11.68-46.084Z"
      />
      <path
        fill="#A3978E"
        d="m70.051 49.494-4.19-9.558h6.286l5.588 4.779 8.382-2.048 9.08 6.827v-7.51l10.477 2.731-4.889 1.365-5.588 10.924-11.176-8.875-6.286 6.144-5.588-9.558-2.096 4.779Z"
      />
      <path
        fill="#fff"
        d="m70.051 47.598-4.19-9.558h6.286l5.588 4.779 8.382-2.048 9.08 6.827v-7.51l10.477 2.73-4.889 1.366-5.588 10.924-11.176-8.876-6.286 6.145-5.588-9.558-2.096 4.779Z"
      />
      <path
        fill="#000"
        fillRule="evenodd"
        d="M63.321 3.743V.111h19.765l1.797 3.632v16.344H63.32V3.743Zm3.594 10.896V3.743h14.374V14.64H66.915ZM90.273 3.743 92.07.111h19.764v19.976H90.273V3.743Zm3.594 10.896V3.743h14.374V14.64H93.867Z"
        clipRule="evenodd"
      />
      <path fill="#000" d="M84.883 5.818v2.854h5.39V5.818h-5.39Z" />
      <ellipse cx="64" cy="158.333" fill="#000" rx="64" ry="12.444" />
    </svg>
  );
}
