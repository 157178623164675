export function JelenaPapigaSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="128"
      height="129"
      fill="none"
      viewBox="0 0 128 129"
    >
      <rect width="128" height="128" fill="#3141CE" rx="64" />
      <path
        fill="#FFF615"
        d="m48.036 62.094 3.124-8.453 11.117 4.686-2.297 3.767h-2.848c-11.02 10.878-9.937 29.788-6.247 40.242-2.606 6.44-5.807 4.253-6.615 2.481l-2.022-5.972c-.308 2.988-.513 4.313-1.102 4.134-1.494-.219-1.204-5.024-1.195-11.485-.126 2.743-.406 4.003-2.48 4.135.158-4.123 1.117-6.785 2.48-11.393-2.135 2.737-3.183 3.953-3.95 3.767 1.628-6.439 3.035-9.474 6.247-14.057-2.39 2.96-3.602 4.293-4.777 4.134 3.267-6.562 5.855-9.96 10.566-15.986Z"
      />
      <path
        fill="#1EC2E6"
        d="M40.227 14.593c16.32-6.583 36.2 0 46.49 17.916l5.053 5.697c.168 1.418-.115 1.722-1.654.918l7.901 15.252 13.139 16.446-3.583-1.746 13.046 12.22-23.796-10.474-6.707-1.746-3.4.735-8.452-4.594-4.686-.826L62.461 62l-2.021-2.11h-9.831l7.442-9.831 13.69-13.782-31.514-21.683Z"
      />
      <path
        fill="#010101"
        d="M37.103 48.771c3.312 4.132 6.832 4.746 14.15 4.778-.34-8.82-2.014-13.242-7.902-20.121-1.201 4.36-1.292 6.911 1.194 11.944l-7.442 3.4Z"
      />
      <path
        fill="#010101"
        d="M31.866 25.71c-7.901 13.966-2.94 24.44 8.085 29.952l-4.41-9.83c3.622-6.212 5.003-8.497 6.523-10.842l-10.198-9.28Z"
      />
      <path
        fill="#CFCFCF"
        d="M40.686 35.909c-1.145-2.79-3.593-5.087-9.095-9.647 3.893-1.373 13.178 5.23 21.774 14.7l-3.123 5.145c-.84-4.15-1.887-6.59-6.064-11.393l-3.492 1.195Z"
      />
      <path
        fill="#39DB00"
        d="M60 128c-8.942-7.796-15.238-34.225-9.758-61.313C56.91 40.112 110.52 74.283 124 86.5c-15 33.5-40 43-64 41.5Z"
      />
      <path
        fill="#44FE02"
        d="M47.76 89.1s20.814 18.368 23.908 14.302c3.093-4.067-7.805-19.35-7.805-19.35s13.71 15.494 18.914 14.302c3.503-.802-11.11-24.889-11.11-24.889S85.52 84.052 88.052 82.58c2.762-1.606-13.29-15.634-13.29-15.634s7.503 4.203 12.305 3.015c4.803-1.188-13.57-11.358-13.57-11.358C46.582 52.412 47.76 71.716 47.76 89.1Z"
      />
      <path
        fill="#46DBFB"
        d="M52.188 56.362S70.45 72.5 73.164 68.928c2.715-3.573-6.848-17-6.848-17s12.03 13.612 16.596 12.565c3.073-.705-9.748-21.866-9.748-21.866s12.154 9.3 14.375 8.007c2.424-1.411-11.66-13.735-11.66-13.735s6.582 3.692 10.796 2.648c4.214-1.043-11.907-9.978-11.907-9.978-6.99 17.268-9.347 18.504-21.219 17.365l-1.36 9.428Z"
      />
      <path
        fill="#FF4E02"
        d="M35.45 14.134c-7.326 7.962-10.13 11.754-3.308 12.403 18.878 9.725 23.01 14.094 19.11 19.846l-3.767 15.619 2.114-.92 6.155-7.809h2.113l2.849-1.562s.918-4.134 2.113-4.134h2.113v-2.664c3.045 2.237 3.253 1.578 3.308 0 1.415-3.864 2.785-5.684 7.074-7.81.299-1.806-.344-2.375-2.48-2.848 11.404-3.214 11.248-4.708.918-6.89-7.927 1.478-11.242 1.029-14.517-2.757-.241-2.21-1.026-3.73-4.96-7.534-3.633-4.079-5.286-5.214-7.627-5.053-2.04-.134-3.491.327-7.166 3.032l.919-3.032c-2.65 1.849-3.614 2.812-3.767 4.318l-1.195-2.205Z"
      />
      <path
        fill="#fff"
        d="M50.517 46.107c-2.116-4.989-3.423-7.815-7.258-13.23.22-.384.428-.75.626-1.103 2.018-3.592 2.939-5.644 3.6-8.453.428-.54 1.394-.831 7.075-1.286 3.521 1.862 3.307 4.594 7.81 6.799-1.887 6.359-3.87 8.091-8.545 8.912h-2.022l-1.286 8.36Z"
      />
      <path
        fill="#000"
        d="m44.27 32.417 6.523 6.891.46-1.47-6.983-5.42Zm7.81 2.389-7.167-3.767c7.888 3.693 11.755 4.159 16.446-1.378l-.919 2.756c-3.08 1.896-4.848 2.756-8.36 2.39Z"
      />
      <path
        fill="#000"
        d="M44.913 32.142c.232.153.46.305.687.458l2.76 1.84c3.44 2.163 6.612 3.237 11.345-.277-2.37 1.76-3.084 3.437-7.626 2.756l-3.72-2.48c-.891-.56-1.8-1.194-2.76-1.84l-.686-.457Zm.459-2.481c4.688 3.384 10.29 2.179 11.2.685a7.81 7.81 0 0 0 .101-.226.907.907 0 0 1-.1.226c-.752 1.615-1.72 2.136-4.494 2.071-2.828-.398-4.334-.883-6.707-2.756Z"
      />
      <circle cx="53.182" cy="27.273" r="3.951" fill="#D8D7D7" />
      <circle cx="53.09" cy="27.364" r="3.308" fill="#000" />
      <circle cx="54.376" cy="25.343" r="1.103" fill="#fff" />
    </svg>
  );
}
