import React, { useEffect, useState } from "react";
import { InfoTag } from "../shared/InfoTag.js";
import { TopRightContainer } from "../shared/containers/TopRightContainer.js";

function TouchIcons() {
  return (
    <>
      <InfoTag>
        Rotate
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_350_2080)">
            <path
              d="M14 5C15.42 5 16.74 5.76 17.45 7C18.56 8.9 17.91 11.35 16 12.46V11.23C16.64 10.67 17 9.85 17 9C17 8.20435 16.6839 7.44129 16.1213 6.87868C15.5587 6.31607 14.7956 6 14 6C13.2044 6 12.4413 6.31607 11.8787 6.87868C11.3161 7.44129 11 8.20435 11 9C11 9.85 11.36 10.67 12 11.23V12.46C10.77 11.75 10 10.43 10 9C10 7.93913 10.4214 6.92172 11.1716 6.17157C11.9217 5.42143 12.9391 5 14 5Z"
              fill="currentColor"
            />
            <path
              d="M13 9C13 8.73478 13.1054 8.48043 13.2929 8.29289C13.4804 8.10536 13.7348 8 14 8C14.2652 8 14.5196 8.10536 14.7071 8.29289C14.8946 8.48043 15 8.73478 15 9V13.47L16.21 13.6L21.15 15.8C21.67 16.04 22 16.56 22 17.14V21.5C21.97 22.32 21.32 22.97 20.5 23H14C13.62 23 13.26 22.85 13 22.57L11.1716 18.5L13 15.8V9Z"
              fill="currentColor"
            />
            <path
              d="M1.48049 10.0902L3.88292 12.4927L6.28535 10.0902L5.56462 9.3695L4.17121 10.7629C4.23527 9.7699 4.46751 8.82495 4.86791 7.92804C5.26832 7.03113 5.82088 6.23032 6.52559 5.52561C7.2303 4.8209 8.03111 4.26834 8.92802 3.86793C9.82492 3.46753 10.7699 3.23529 11.7629 3.17123L10.3695 4.56464L11.0902 5.28537L13.4926 2.88294L11.0902 0.480508L10.3695 1.20124L11.3304 2.16221C10.2814 2.26631 9.2863 2.54452 8.34519 2.99681C7.4044 3.44943 6.55762 4.05212 5.80486 4.80488C5.0521 5.55764 4.44941 6.40442 3.99679 7.34521C3.54449 8.28632 3.26629 9.28141 3.16219 10.3305L2.20121 9.3695L1.48049 10.0902Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="clip0_350_2080">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </InfoTag>
      <InfoTag>
        Zoom
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13 9C13 8.73478 13.1054 8.48043 13.2929 8.29289C13.4804 8.10536 13.7348 8 14 8C14.2652 8 14.5196 8.10536 14.7071 8.29289C14.8946 8.48043 15 8.73478 15 9V13.47L16.21 13.6L21.15 15.8C21.67 16.04 22 16.56 22 17.14V21.5C21.97 22.32 21.32 22.97 20.5 23H14C13.62 23 13.26 22.85 13 22.57L8.1 18.37L8.84 17.6C9.03 17.39 9.3 17.28 9.58 17.28H9.8L13 19V9ZM14 5C15.42 5 16.74 5.76 17.45 7C18.56 8.9 17.91 11.35 16 12.46V11.23C16.64 10.67 17 9.85 17 9C17 8.20435 16.6839 7.44129 16.1213 6.87868C15.5587 6.31607 14.7956 6 14 6C13.2044 6 12.4413 6.31607 11.8787 6.87868C11.3161 7.44129 11 8.20435 11 9C11 9.85 11.36 10.67 12 11.23V12.46C10.77 11.75 10 10.43 10 9C10 7.93913 10.4214 6.92172 11.1716 6.17157C11.9217 5.42143 12.9391 5 14 5ZM4 9L7 12H5V15H3V12H1L4 9ZM4 7L1 4H3V1H5V4H7L4 7ZM9 14C9.73 14 10.41 14.19 11 14.54V15.76C10.47 15.29 9.77 15 9 15C8.20435 15 7.44129 15.3161 6.87868 15.8787C6.31607 16.4413 6 17.2044 6 18C6 19 6.5 19.87 7.22 20.42L9.31 22H9C7.93913 22 6.92172 21.5786 6.17157 20.8284C5.42143 20.0783 5 19.0609 5 18C5 16.9391 5.42143 15.9217 6.17157 15.1716C6.92172 14.4214 7.93913 14 9 14Z"
            fill="currentColor"
          />
        </svg>
      </InfoTag>
      <InfoTag>
        Move
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13 9C13 8.73478 13.1054 8.48043 13.2929 8.29289C13.4804 8.10536 13.7348 8 14 8C14.2652 8 14.5196 8.10536 14.7071 8.29289C14.8946 8.48043 15 8.73478 15 9V13.47L16.21 13.6L21.15 15.8C21.67 16.04 22 16.56 22 17.14V21.5C21.97 22.32 21.32 22.97 20.5 23H14C13.62 23 13.26 22.85 13 22.57L8.1 18.37L8.84 17.6C9.03 17.39 9.3 17.28 9.58 17.28H9.8L13 19V9ZM14 5C15.42 5 16.74 5.76 17.45 7C18.56 8.9 17.91 11.35 16 12.46V11.23C16.64 10.67 17 9.85 17 9C17 8.20435 16.6839 7.44129 16.1213 6.87868C15.5587 6.31607 14.7956 6 14 6C13.2044 6 12.4413 6.31607 11.8787 6.87868C11.3161 7.44129 11 8.20435 11 9C11 9.85 11.36 10.67 12 11.23V12.46C10.77 11.75 10 10.43 10 9C10 7.93913 10.4214 6.92172 11.1716 6.17157C11.9217 5.42143 12.9391 5 14 5ZM9 14C9.73 14 10.41 14.19 11 14.54V15.76C10.47 15.29 9.77 15 9 15C8.20435 15 7.44129 15.3161 6.87868 15.8787C6.31607 16.4413 6 17.2044 6 18C6 19 6.5 19.87 7.22 20.42L9.31 22H9C7.93913 22 6.92172 21.5786 6.17157 20.8284C5.42143 20.0783 5 19.0609 5 18C5 16.9391 5.42143 15.9217 6.17157 15.1716C6.92172 14.4214 7.93913 14 9 14Z"
            fill="currentColor"
          />
          <path
            d="M7.14265 2.14286L4.99979 0L2.85693 2.14286H4.2855V4.28571H5.71408V2.14286H7.14265Z"
            fill="currentColor"
          />
          <path
            d="M2.85735 7.85714L5.00021 10L7.14307 7.85714H5.7145V5.71429H4.28592V7.85714H2.85735Z"
            fill="currentColor"
          />
          <path
            d="M2.14286 2.85711L0 4.99997L2.14286 7.14282V5.71425H4.28571V4.28568H2.14286L2.14286 2.85711Z"
            fill="currentColor"
          />
          <path
            d="M7.85714 7.14289L10 5.00003L7.85714 2.85718V4.28575H5.71429V5.71432H7.85714V7.14289Z"
            fill="currentColor"
          />
        </svg>
      </InfoTag>
    </>
  );
}
function DesktopIcons() {
  return (
    <>
      <InfoTag>
        Rotate
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_471_2365)">
            <path
              d="M9 9.4C9 8.49826 9.34768 7.63346 9.96655 6.99584C10.5854 6.35821 11.4248 6 12.3 6H16.7C17.5752 6 18.4146 6.35821 19.0335 6.99584C19.6523 7.63346 20 8.49826 20 9.4V19.6C20 20.5017 19.6523 21.3665 19.0335 22.0042C18.4146 22.6418 17.5752 23 16.7 23H12.3C11.4248 23 10.5854 22.6418 9.96655 22.0042C9.34768 21.3665 9 20.5017 9 19.6V9.4ZM12.3 7.13333C11.7165 7.13333 11.1569 7.37214 10.7444 7.79722C10.3318 8.22231 10.1 8.79884 10.1 9.4V14.5V19.6C10.1 20.2012 10.3318 20.7777 10.7444 21.2028C11.1569 21.6279 11.7165 21.8667 12.3 21.8667H16.7C17.2835 21.8667 17.8431 21.6279 18.2556 21.2028C18.6682 20.7777 18.9 20.2012 18.9 19.6V9.4C18.9 8.79884 18.6682 8.22231 18.2556 7.79722C17.8431 7.37214 17.2835 7.13333 16.7 7.13333C12.3142 7.13333 16.4829 7.13333 14.5 7.13333C14.1498 7.13333 13.0747 7.13333 12.3 7.13333Z"
              fill="currentColor"
            />
            <path
              d="M12.3 7.13333C11.7165 7.13333 11.1569 7.37214 10.7444 7.79722C10.3318 8.22231 10.1 8.79884 10.1 9.4V14.5H14.5V7.13333H12.3Z"
              fill="currentColor"
            />
            <path
              d="M1.48049 10.0902L3.88292 12.4927L6.28535 10.0902L5.56462 9.3695L4.17121 10.7629C4.23527 9.7699 4.46751 8.82495 4.86791 7.92804C5.26832 7.03113 5.82088 6.23032 6.52559 5.52561C7.2303 4.8209 8.03111 4.26834 8.92802 3.86793C9.82492 3.46753 10.7699 3.23529 11.7629 3.17123L10.3695 4.56464L11.0902 5.28537L13.4926 2.88294L11.0902 0.480508L10.3695 1.20124L11.3304 2.16221C10.2814 2.26631 9.2863 2.54452 8.34519 2.99681C7.4044 3.44943 6.55762 4.05212 5.80486 4.80488C5.0521 5.55764 4.44941 6.40442 3.99679 7.34521C3.54449 8.28632 3.26629 9.28141 3.16219 10.3305L2.20121 9.3695L1.48049 10.0902Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="clip0_471_2365">
              <rect width="24" height="24" fill="currentColor" />
            </clipPath>
          </defs>
        </svg>
      </InfoTag>
      <InfoTag>
        Zoom
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 9.4C9 8.49826 9.34768 7.63346 9.96655 6.99584C10.5854 6.35821 11.4248 6 12.3 6H16.7C17.5752 6 18.4146 6.35821 19.0335 6.99584C19.6523 7.63346 20 8.49826 20 9.4V19.6C20 20.5017 19.6523 21.3665 19.0335 22.0042C18.4146 22.6418 17.5752 23 16.7 23H12.3C11.4248 23 10.5854 22.6418 9.96655 22.0042C9.34768 21.3665 9 20.5017 9 19.6V9.4ZM12.3 7.13333C11.7165 7.13333 11.1569 7.37214 10.7444 7.79722C10.3318 8.22231 10.1 8.79884 10.1 9.4V14.5V19.6C10.1 20.2012 10.3318 20.7777 10.7444 21.2028C11.1569 21.6279 11.7165 21.8667 12.3 21.8667H16.7C17.2835 21.8667 17.8431 21.6279 18.2556 21.2028C18.6682 20.7777 18.9 20.2012 18.9 19.6V9.4C18.9 8.79884 18.6682 8.22231 18.2556 7.79722C17.8431 7.37214 17.2835 7.13333 16.7 7.13333C12.3142 7.13333 16.4829 7.13333 14.5 7.13333C14.1498 7.13333 13.0747 7.13333 12.3 7.13333Z"
            fill="currentColor"
          />
          <path d="M13.95 8.55H15.05V12.2333H13.95V8.55Z" fill="currentColor" />
          <path d="M5 2L8 5H6V8H4V5H2L5 2Z" fill="currentColor" />
          <path d="M2 11L5 14L8 11H6V8H4V11H2Z" fill="currentColor" />
        </svg>
      </InfoTag>
      <InfoTag>
        Move
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 9.4C9 8.49826 9.34768 7.63346 9.96655 6.99584C10.5854 6.35821 11.4248 6 12.3 6H16.7C17.5752 6 18.4146 6.35821 19.0335 6.99584C19.6523 7.63346 20 8.49826 20 9.4V19.6C20 20.5017 19.6523 21.3665 19.0335 22.0042C18.4146 22.6418 17.5752 23 16.7 23H12.3C11.4248 23 10.5854 22.6418 9.96655 22.0042C9.34768 21.3665 9 20.5017 9 19.6V9.4ZM12.3 7.13333C11.7165 7.13333 11.1569 7.37214 10.7444 7.79722C10.3318 8.22231 10.1 8.79884 10.1 9.4V14.5V19.6C10.1 20.2012 10.3318 20.7777 10.7444 21.2028C11.1569 21.6279 11.7165 21.8667 12.3 21.8667H16.7C17.2835 21.8667 17.8431 21.6279 18.2556 21.2028C18.6682 20.7777 18.9 20.2012 18.9 19.6V14.5V9.4C18.9 8.79884 18.6682 8.22231 18.2556 7.79722C17.8431 7.37214 17.2835 7.13333 16.7 7.13333C12.3142 7.13333 16.4829 7.13333 14.5 7.13333C14.1498 7.13333 13.0747 7.13333 12.3 7.13333Z"
            fill="currentColor"
          />
          <path
            d="M18.9 9.4C18.9 8.79884 18.6682 8.22231 18.2556 7.79722C17.8431 7.37214 17.2835 7.13333 16.7 7.13333H14.5V14.5H18.9V9.4Z"
            fill="currentColor"
          />
          <path
            d="M7.14265 2.14286L4.99979 0L2.85693 2.14286H4.2855V4.28571H5.71408V2.14286H7.14265Z"
            fill="currentColor"
          />
          <path
            d="M2.85735 7.85714L5.00021 10L7.14307 7.85714H5.7145V5.71429H4.28592V7.85714H2.85735Z"
            fill="currentColor"
          />
          <path
            d="M2.14286 2.85711L0 4.99997L2.14286 7.14282V5.71425H4.28571V4.28568H2.14286L2.14286 2.85711Z"
            fill="currentColor"
          />
          <path
            d="M7.85714 7.14289L10 5.00003L7.85714 2.85718V4.28575H5.71429V5.71432H7.85714V7.14289Z"
            fill="currentColor"
          />
        </svg>
      </InfoTag>
    </>
  );
}
function GuideInfo() {
  const [isTouchDevice, setIsTouchDevice] = useState(false);

  useEffect(() => {
    const handleTouchStart = () => {
      setIsTouchDevice(true);
    };

    if ("ontouchstart" in window) {
      setIsTouchDevice(true);
    } else {
      window.addEventListener("touchstart", handleTouchStart);
    }

    return () => window.removeEventListener("touchstart", handleTouchStart);
  }, []);

  return (
    <TopRightContainer>
      {isTouchDevice ? (
        // Render the touch version of the component
        <TouchIcons />
      ) : (
        // Render the desktop version of the component
        <DesktopIcons />
      )}
    </TopRightContainer>
  );
}

export default GuideInfo;
