export function IndividualCatSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="128"
      height="171"
      fill="none"
      viewBox="0 0 128 171"
    >
      <rect width="128" height="128" y="25" fill="#07BEB8" rx="64" />
      <path
        fill="#FFBA53"
        d="m5.986 73.391 7.273 19.183 17.015 25.842 11.709-33.482-1.985-12.777 1.985-6.183-6.355 1.236 2.359-15.645-9.632-3.537-14.633-5.897-10.667 7.276 2.93 23.984Z"
      />
      <path
        fill="#fff"
        d="m24.98 48.993 4.546 8.943-3.126.811-4.01-4.02-3.64 3.114-6.273-5.603 1.935 7.452-5.913-1.357 2.08-1.986-.036-11.56 7.843 7.364 1.543-6.909 5.24 8.763-.189-5.012Z"
      />
      <path
        fill="#FFCF71"
        d="M74.583 7.154c2.474-3.617 3.864-4.347 13.13-5.52h4.567l11.036 6.28 4.757-2.664 2.456 14.829 2.845 1.423-1.422 7.114 2.845 1.424-2.538 7.114v7.115l3.616 9.961v29.058l-1.078 6.517 1.078 6.236v17.891l-11.038 24.254-1.521 12.807v15.652l-19.824 1.423-9.67 1.633H54.603v-24.553h-7.802l-6.47 13.894H32.53l-9.134-24.173 10.292-36.527 8.538-27.036 9.96-24.19 4.27-14.23-4.27-4.27 7.115-1.422-2.846-2.846 4.808-5.203L65.069.11l9.514 7.043Z"
      />
      <path
        fill="#1B1A1A"
        d="m74.962 10.771 3.616-2.284 3.044 1.713-1.712.952-4.948-.38Zm26.944 1.054-3.01-3.037-3.352.986L97 11.086l4.906.739Z"
      />
      <path
        fill="#FC9951"
        d="m87.338 6.391-3.235 3.426 5.328 1.332 4.187-.951-.381-1.713-2.283-2.094h-3.616Z"
      />
      <path
        fill="#fff"
        d="m63.743 17.132 1.89-14.184 2.837 4.728-2.837 11.347-13.235 26.478-4.727 28.37-9.715 23.049-11.083 23.286 8.238-26.132 6.888-22.095 13.033-33.401 8.71-21.446Zm43.401 1.612 2.925 4.063-2.501-8.27-1.467-6.524-1.308 4.543.672 1.768 1.679 4.42Z"
      />
      <path
        fill="#39264C"
        d="M106.269 44.269H69.272L56.465 32.885 49.35 48.538 32.274 99.764l38.42 24.19 45.536-19.921 1.423-21.345 1.423-17.076-2.846-14.23-2.846-17.075-7.115 9.96Z"
      />
      <path
        fill="#FFBA53"
        d="m58.788 91.565-1.026-5.598 8.142-2.94 7.255.118 8.024 4.316-3.943 2.17 5.226 4.828-2.543 1.913 1.282 6.998-3.312-2.286.279 17.309 4.757 33.849-6.742 2.682-8.025-4.316-8.838-40.334-3.196-9.541 4.2-.77-7.138-7.372 5.598-1.026Z"
      />
      <path
        fill="#fff"
        d="m57.857 106.049-.922-4.278.604-1.25.926.92 1.618 4.129-.187 1.159 1.34 4.189-.048 1.129 1.153 5.349-.74-2.08 2.49 12.896 5.393 25.701-.465 1.22-1.617-4.129-6.826-30.989-1.753-7.458.418-.09-1.94-6.298.556-.12Z"
      />
      <path
        fill="#FFBA53"
        d="m108.437 91.027.885-5.623-8.213-2.732-7.25.3-7.913 4.518 3.996 2.069-5.101 4.96 2.59 1.847-1.105 7.029 3.254-2.37.158 17.311-5.004 40.986 6.807 2.511 7.913-4.517 8.921-47.573 2.953-9.619-4.217-.663 6.949-7.55-5.623-.884Z"
      />
      <path
        fill="#fff"
        d="m90.116 103.926.353-4.363.939-1.022.62 1.148.357 4.42-.513 1.056.073 4.397-.372 1.068-.44 5.453-.108-2.204-1.34 13.065-2.257 26.164-.798 1.033-.356-4.42 2.413-31.64.475-7.646.425.034-.038-6.589.567.046Z"
      />
      <path
        fill="#A3978E"
        d="m71.71 49.36-4.179-9.532h6.27l5.572 4.766 8.36-2.043 9.055 6.809v-7.49l10.449 2.724-4.876 1.361-5.573 10.894-11.145-8.851-6.27 6.128-5.572-9.532-2.09 4.766Z"
      />
      <path
        fill="#fff"
        d="m71.71 47.469-4.179-9.532h6.27l5.572 4.766 8.36-2.043 9.055 6.809v-7.49l10.449 2.724-4.876 1.361-5.573 10.894-11.145-8.85-6.27 6.127-5.572-9.532-2.09 4.766Zm23.233-26.555h-12.29l.945-.945 1.891-1.892 7.563-.945v2.837h.946l.945.945Z"
      />
      <path
        fill="#F9A2A2"
        d="m83.908 20.476.76-7.994 4.377-1.904 4.567 1.142 1.332 4.188-1.712 4.568L92.47 18l-1.903 1.523H86.38L85.811 18l-1.903 2.475Z"
      />
      <ellipse cx="64" cy="158.333" fill="#000" rx="64" ry="12.444" />
    </svg>
  );
}
