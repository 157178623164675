export function MagareVinkoSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={136}
      height={134}
      fill="none"
    >
      <rect width={128} height={128} x={5} y={6} fill="#FFB422" rx={64} />
      <path
        fill="#3F3423"
        fillRule="evenodd"
        d="M37.658 53.06c-25.589 5.721-38.467 8.6-35.7 35.357 1.988 3.77 4.061 4.516 8.651 4.57l.49 2.774 5.876 3.265-1.633 4.406s-.375 2.813.327 4.746c1.197 3.297 8.487 2.938 8.487 2.938l12.241-7.031q1.41.514 2.708.994c7.368 2.721 12.242 4.521 18.674 3.576l3.59 2.938 4.407-1.142L78.344 116c9.392-1.609 13.012-4.402 18.117-10.935l17.3 6.528 2.449 1.907C127.798 101 134 86.5 133 66l-.469-2.555-1.795 1.795-1.306-3.264-1.632 1.959-.653-2.449-1.795 1.633-.816-3.428-1.633 1.795-.489-2.448-2.448.653.816-1.143-1.959.49v-2.285h-2.611v-1.632l-2.449.49 2.449-10.12 16.974-25.298c4.718-23.026 2.278-25.915-12.241-10.936l-13.547 25.299-14.526 8.976-16.975-.979C61.1 47.821 48.283 50.686 37.658 53.061"
        clipRule="evenodd"
      />
      <path
        fill="#584F41"
        d="M107.396 13.011C100.239 1.92 92.87 43.532 92.87 43.532l14.526-8.976s4.562-14.475 0-21.545"
      />
      <path
        fill="#AF7F7F"
        d="m11.262 95.925-.98-4.407 12.078-13.71 3.428 3.427 2.775 5.713-.816 8.16z"
      />
      <path
        fill="#EEE6D0"
        d="M70.999 55.12s-1.894-4.442-1.143-7.18c1.67-6.086 15.995-2.286 15.995-2.286s5.767 2.685 9.14 3.918c.95 5.283 6.413 5.974 9.63 9.303-1.113 3.264-8.616 7.711-15.342 7.834-8.451.155-18.28-11.588-18.28-11.588M108.049 49.408l-3.101-3.917c4.346-6.74 6.759-10.683 11.425-23.83 3.588-5.517 5.112-7.95 8.813-12.73 3.918-5.06 6.708-6.084 7.835 0L129.92 23.62l-7.019 13.22-4.896 4.244-1.632-1.143 3.101-3.754s4.08-10.772 4.08-12.567c0-1.082-1.542-2.957-1.958-1.959-.816 1.959-6.366 17.138-6.366 17.138v3.264l-5.059 5.876z"
      />
      <path
        fill="#D1CAB7"
        d="M37.376 52.999c1.363 15.57 3.13 24.182 8.324 39.335 1.271 6.044-.408 8.531-8.324 11.099l-7.52 3.063c-.738.921-1.88 1.941-3.578 3.139-2.786.935-4.523 1.25-9.14 0-2.88-3.957-2.12-6.067 0-9.793 0 0 .23 4.57 2.938 4.407 1.801-.109 2.284-1.959 2.284-1.959 1.27-3.693 1.89-5.789 2.612-9.63 2.514-4.328 3.251-6.805-2.612-11.751l-6.202 8.16-3.1 3.591h-4.57c-2.376-.09-3.504-.693-5.387-2.121C1.475 89.159.7 87.789 0 82.378l1.143-4.733.71-2.367-.22-.082.458-.714 1.01-3.366c1.616-5.17 6.11-10.18 13.323-12.339C21.974 55.7 27.193 54.161 37.376 53"
      />
      <path
        fill="#EEE1BF"
        d="M20.239 92.17c-4.825 2.737-7.274 3.943-9.304 3.102 1.197 1.898.626 3.205 3.428 3.917 3.86-.203 5.844.039 7.508-3.1z"
      />
      <path
        fill="#000"
        fillRule="evenodd"
        d="M85.96 51.707a5.39 5.39 0 0 0-4.845 4.064l.003.003c.724.635 1.257 1.293 1.762 1.916.892 1.099 1.695 2.089 3.298 2.654 1.24.437 2.987.373 4.254.239a5.4 5.4 0 0 0 1.162-2.308c-.293-1.062-.732-2.307-1.336-3.154-1.252-1.755-2.38-2.786-4.297-3.415"
        clipRule="evenodd"
      />
      <path
        fill="#000"
        d="M101.356 60.796C93.047 52.642 88.519 49.12 81.07 47.882c0 0 6.567-.521 10.273 1.072 5.564 2.392 10.013 11.842 10.013 11.842"
      />
      <circle cx={85.198} cy={53.652} r={0.979} fill="#fff" />
    </svg>
  );
}
