export function ZvrkiHeadSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="78"
      height="79"
      fill="none"
      viewBox="0 0 78 79"
    >
      <path
        fill="#817C77"
        d="m62.981 33.962 3.17 4.85-.534 6.724 1.773 6.652-1.773 8.982L53 63.69l-3.764 5.513-12.927-3.447-8.64 3.647 1.084-4.06-6.197-1.652 1.225-4.594-4.672 2.073 3.305-3.81-6.81-5.364-12.071-3.219 7.884-6.823.598-2.243 6.98-.428-4.003-8.162s2.145 5.966 5.512-6.082C23.872 12.99 35.633 2.943 36.23 5.545c2.039-.238 6.645 6.62 4.031 16.638l3.049-1.133 3.148 2.785 2.727-1.218 1.525 3.725 4.508-.171c6.01-3.251 10.341-6.29 12.435-4.58l-4.672 12.371Z"
      />
      <path
        fill="#fff"
        d="m60.694 35.014.259 6.123 2.792 3.262 2.728 5.648-2.834 13.63-11.139-1.34-5.046 6.613-12.46-3.323-8.325 3.51 1.043-3.91-5.973-1.592 1.18-4.423-4.503 1.995 3.184-3.67-6.563-5.165-10.674-2.847 6.635-6.825 14.383-7.614-1.147 12.576 8.449-7.36 7.756-5.484 7.26-1.906 6.65-2.624 6.345 4.727Z"
      />
      <path
        fill="#E3ACAC"
        d="m63.038 45.764 1.175.313-.249 2.222-1.695.35-2.927 3.681c.856-2.76.68-4.286-2.735-6.908 1.794-1.37 2.872-1.511 4.871-1.104l1.56 1.446Z"
      />
      <path
        fill="#D9D9D9"
        d="m42.97 47.733.269 2.297.828 4.227 13.338.189.045 4.981 6.014-1.365-1.49.11-3.926.728.335-5.047 1.244-1.917-1.72 1.246-13.106.47-1.831-5.92Z"
      />
      <path
        fill="#000"
        d="M42.42 33.4c3.484-1.092 6.13.688 8.802 4.75 0 0-1.68-4.593-4.23-5.59-1.934-.757-5.313.184-5.313.184l.74.656Z"
      />
      <path
        fill="#000"
        d="M49.542 37.588c-3.626.436-4.672-1.36-7.2-4.324 2.937 4.004 4.259 5.245 8.425 4.88l-1.225-.556Z"
      />
      <path
        fill="#D9D9D9"
        d="M49.27 37.744c.123-1.368-.127-1.956-.84-2.856-2.749-3.023-3.823-2.145-5.854-1.218 2.52 3.067 3.955 4.45 6.695 4.074Z"
      />
      <path
        fill="#000"
        d="M47 37.836s.589-.088-.533-2.044c-.42-.67-.814-.694-1.594-.565-.255.429-.343.572-.37.613l-.003-.004s-.021.04.002.004c.308.578 1.936 1.818 2.498 1.996Z"
      />
      <path
        fill="#D9D9D9"
        d="M62.696 43.613c.468-1.157.689-1.79.363-2.65-.671-.43-1.129-.292-1.987.157l1.624 2.493Z"
      />
      <path
        fill="#000"
        d="M61.711 41.07c1.287-.59.906.398 1.887-.057-1.162-.578-1.094-1.015-2.734.048l.847.01Zm.016 1.026.273.34s.499-.6-.273-.34Z"
      />
      <path
        fill="#A5A5A5"
        d="M38.59 23.73c2.47-11.29 1.226-13.945-2.23-16.957-9.344 4.145-11.26 9.333-14.7 24.23l2.984-3.896 2.443-.15.77-2.884 2.307 1.645 1.325-2.393 1.346 3.105.541-2.03 2.03.542.513-1.923 2.67.712Z"
      />
    </svg>
  );
}
